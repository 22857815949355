import type {
    ComposableOptionsBase,
    UserProfile,
    SetPassword,
    SignInResponse,
    Login,
    UserSignUpRequest,
    ForgotPassword,
    VerifyResetToken,
    ResetPassword,
    DefaultAddressType,
    Address,
    StripeShippingObject,
    UserProfileUpdateResponse,
    Membership,
    SignUpStep1Response,
    SignUpStep2Request,
    SignUpStep2Response,
    ErrorTags
} from '~/types';
import {
    useApiUtils,
    useStripeUtils,
    useCustomFetch,
    useUserUtils,
    useCustomAuth
} from '~/composables';
import { UserModel } from '~/models';
import { isError as lo_isError } from 'es-toolkit';
import type { NitroFetchOptions } from 'nitropack';
import type { FetchError } from 'ofetch';
import type { NuxtError } from '#app';

export interface UseUserOptions extends ComposableOptionsBase {}
export function useUser(options: Partial<UseUserOptions> = {}) {
    const {
        isLoggedIn,
        setSession,
        getSession,
        sessionObj: userObj,
        signIn: authSignIn
    } = useCustomAuth();
    const apiBasePath = `/v1/users`;
    const apiBaseAddressesPath = `/v1/addresses`;
    const $_fetch = useCustomFetch({ siteContext: options.siteContext });

    const useApiUtilsObj = useApiUtils();
    const useStripeUtilsObj = useStripeUtils();
    const useUserUtilsObj = useUserUtils();

    const accountCreditBalance = computed<number>(() => userObj.value?.accountCreditBalance ?? 0);

    const defaultBillingAddressId = computed<Nullable<string>>(() => userObj.value?.defaultBillingAddressUid ?? null);
    const defaultShippingAddressId = computed<Nullable<string>>(() => userObj.value?.defaultShippingAddressUid ?? null);

    const hasMemberships = computed<boolean>(() => Boolean(userObj.value?.memberships?.length));

    /**
    *  @deprecated Use currentMembershipUid
    */
    const selectedMembershipUid = computed<string>(() => userObj.value?.currentMembership?.uid ?? '');
    /**
    *  @deprecated Use currentMembership
    */
    const currentMembershipObj = computed<Nullable<Membership>>(() => userObj.value?.currentMembership ?? null);

    const currentMembership = currentMembershipObj;
    const currentMembershipUid = selectedMembershipUid;

    const isReseller = computed<boolean>(() => currentMembership.value?.entity?.isRetail ?? false);

    async function fetchUser(force = false): Promise<boolean | Error> {
        try {
            await getSession(force);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function signIn(payload: Login): Promise<SignInResponse | Error> {
        try {
            const response = await authSignIn(payload);

            if (!lo_isError(response) && response?.user?.uid) {
                useUserUtilsObj.setUserIdCookie(response.user.uid);
            }

            return response;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function signUpStep1(signUpObj: Partial<UserSignUpRequest>): Promise<SignUpStep1Response | ErrorTags | Error> {
        const url = `${apiBasePath}/sign-up/`;
        const config: NitroFetchOptions<string> = {
            method: 'POST',
            body: signUpObj
        };

        try {
            return await $_fetch<SignUpStep1Response>(url, config);
        } catch (err) {
            const errObj = err as FetchError;
            const errorTag = errObj?.data?.[0];

            if (errorTag) {
                return {
                    errors: [errorTag]
                };
            }

            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function signUpStep2(signUpObj: SignUpStep2Request): Promise<SignUpStep2Response | Error> {
        const url = `${apiBasePath}/token-set-password/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: signUpObj
        };

        try {
            return await $_fetch<SignUpStep2Response>(url, config);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getItem(uid: string): Promise<UserModel | Error> {
        const url = `${apiBasePath}/${uid}/`;

        try {
            const response = await $_fetch<UserProfile>(url);
            return UserModel.toPlainObject<UserModel>(response);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function updateUser(userProfile: Partial<UserProfile>): Promise<UserModel | Error | void> {
        const uid = userProfile.uid ?? userObj.value?.uid;

        if (!uid) {
            // shouldn't normally get here; do some sort of "soft logout" and some clean up via $auth
            return;
        }

        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: userProfile
        };

        const url = `${apiBasePath}/${uid}/`;

        try {
            const response = await $_fetch<UserProfileUpdateResponse>(url, config);
            const userObj = response.user;

            // TODO: check if user is valid and response is not an error

            setSession(userObj);
            return UserModel.toPlainObject<UserModel>(userObj);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setPassword(setPasswordObj: SetPassword): Promise<Error | boolean> {
        const url = `${apiBasePath}/set-password/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: setPasswordObj
        };

        try {
            const response = await $_fetch<UserProfile>(url, config);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function forgotPassword(forgotPasswordObj: ForgotPassword): Promise<Error | boolean> {
        const url = `${apiBasePath}/forgot-password/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: forgotPasswordObj
        };

        try {
            await $_fetch(url, config);
            return true;
        } catch (err) {
            const errObj = err as NuxtError | Error;

            if ('statusCode' in errObj && errObj.statusCode === 404) {
                return false;
            }

            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function resetPassword(resetPasswordObj: ResetPassword): Promise<Error | boolean> {
        const url = `${apiBasePath}/reset-password/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: resetPasswordObj
        };

        try {
            await $_fetch(url, config);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function verifyResetToken(verifyResetTokenObj: VerifyResetToken): Promise<Error | boolean> {
        const url = `${apiBasePath}/validate-password-token/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: verifyResetTokenObj
        };

        try {
            await $_fetch(url, config);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setDefaultAddress(
        uid: Nullable<string>,
        type: DefaultAddressType = 'shipping'
    ): Promise<Error | boolean> {
        const url = `${apiBasePath}/set-default-${type}-address/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: { addressUid: uid }
        };

        try {
            await $_fetch(url, config);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getDefaultAddress(type: DefaultAddressType = 'shipping'): Promise<Address | Error | void> {
        const uid = defaultShippingAddressId.value;

        if (!uid) {
            return;
        }

        const url = `${apiBaseAddressesPath}/${uid}/`;

        try {
            const response = await $_fetch<Address>(url);
            return response;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getDefaultAddressForStripe(): Promise<StripeShippingObject | Error | void> {
        try {
            const response = await getDefaultAddress();

            if (response && !lo_isError(response)) {
                return useStripeUtilsObj.toStripeAddress(response);
            }
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setMembershipUid(membershipUid: string): Promise<UserProfile | NuxtError> {
        const url = `${apiBasePath}/current/select-membership/`;
        const config: NitroFetchOptions<string> = {
            method: 'POST',
            body: {
                membershipUid
            }
        };

        try {
            return await $_fetch<UserProfile>(url, config);
        } catch (err) {
            return useApiUtilsObj.getNuxtErrorObj(err);
        }
    }

    return {
        userObj,
        accountCreditBalance,
        fetchUser,
        isLoggedIn,
        hasMemberships,
        selectedMembershipUid,
        currentMembershipObj,
        currentMembership,
        currentMembershipUid,
        isReseller,
        signIn,
        signUpStep1,
        signUpStep2,
        getItem,
        updateUser,
        setPassword,
        forgotPassword,
        resetPassword,
        verifyResetToken,
        setDefaultAddress,
        getDefaultAddress,
        getDefaultAddressForStripe,
        defaultBillingAddressId,
        defaultShippingAddressId,
        setMembershipUid
    };
}
